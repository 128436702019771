import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useSnackbar } from "src/components/CustomSnackbar";
import { BranchIT } from "src/pages/Branch/interface";
import { getBranchConfig } from "src/pages/Home/service";
import { createCategory, getConfigCategory, uploadImages } from "../service";
import { EditCategoryModalProps } from "../interface";
import { useSelector } from "react-redux";
import { RootState } from "src/redux-persist/store";

const EditCategoryModal: React.FC<EditCategoryModalProps> = ({
  open,
  onClose,
  token,
}) => {
  const { showSnackbar } = useSnackbar();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const userRole = useSelector((state: RootState) => state.user.userRole);
  const userBranch = useSelector((state: RootState) => state.user.userBranch);

  const [branches, setBranches] = useState<BranchIT[]>([]);
  const [categories, setCategories] = useState<
    {
      title: string;
      category: string[];
      _id: string;
      desVi: string;
      desEn: string;
      desJp: string;
      image: string;
    }[]
  >([]);
  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [currentCategory, setCurrentCategory] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState(false);
  const [newCategory, setNewCategory] = useState<string>("");

  const [nameVi, setNameVi] = useState<string>("");
  const [nameEn, setNameEn] = useState<string>("");
  const [nameJp, setNameJp] = useState<string>("");
  const [imageURL, setImageURL] = useState<string>("");

  // Fetch branches
  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const data = await getBranchConfig("branch", token);
        setBranches(data.config.option_content || []);
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    };

    fetchBranches();
  }, [token]);

  useEffect(() => {
    if (!selectedBranch) return;

    const fetchCategories = async () => {
      setLoading(true);
      try {
        const response = await getConfigCategory(selectedBranch);
        const fetchedCategories = response.data?.config?.option_content || [];
        setCategories(fetchedCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
        setCategories([]);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, [selectedBranch]);

  useEffect(() => {
    const selected = categories.find(
      (cat) => cat.category[0] === selectedCategory
    );
    if (selected) {
      setCurrentCategory(selected);
      setNameVi(selected.desVi);
      setNameEn(selected.desEn);
      setNameJp(selected.desJp);
      setImageURL(selected.image);
      setNewCategory(selected.category[0]);
    }
  }, [selectedCategory, categories]);

  const handleBoxClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      console.log("Selected file:", selectedFile);

      try {
        setUploading(true);

        const headers = {
          "X-Authorization": token,
        };

        const uploadedImageUrl = await uploadImages(selectedFile, headers);

        setImageURL(uploadedImageUrl.src);
        console.log("Image uploaded successfully:", uploadedImageUrl);
      } catch (error) {
        console.error("Error uploading image:", error);
        alert("Failed to upload image");
      } finally {
        setUploading(false);
      }
    }
  };

  const handleUpdateCategory = async () => {
    if (!selectedBranch || !selectedCategory) {
      showSnackbar("Hãy chọn chi nhánh và thể loại để chỉnh sửa!", "warning");
      return;
    }

    try {
      const updatedCategory = {
        ...currentCategory,
        desVi: nameVi,
        desEn: nameEn,
        desJp: nameJp,
        image: imageURL,
        category: newCategory ? [newCategory] : currentCategory.category,
      };

      const updatedCategories = categories.map((cat) =>
        cat.category[0] === selectedCategory ? updatedCategory : cat
      );

      const categoryData = {
        type: `category_${selectedBranch}`,
        option_content: JSON.stringify(updatedCategories),
      };

      await createCategory(categoryData, token);
      showSnackbar("Cập nhật thể loại thành công!", "success");
      onClose();
    } catch (error) {
      console.error("Error updating category:", error);
      showSnackbar("Cập nhật thể loại thất bại.", "error");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Chỉnh sửa thể loại</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel>Chi nhánh</InputLabel>
          <Select
            value={selectedBranch}
            label="Chi nhánh"
            onChange={(e) => {
              setSelectedBranch(e.target.value);
              setSelectedCategory("");
              setCurrentCategory(null);
            }}
          >
            {userRole === "super-admin" ? (
              branches.map((branch) => (
                <MenuItem key={branch.key} value={branch.key}>
                  {branch.title}
                </MenuItem>
              ))
            ) : (
              <MenuItem key={userBranch || ""} value={userBranch || ""}>
                {branches.find((branch) => branch.key === userBranch)?.title ||
                  "Chi nhánh không xác định"}
              </MenuItem>
            )}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal" disabled={!selectedBranch}>
          <InputLabel>Thể loại</InputLabel>
          <Select
            value={selectedCategory}
            label="Thể loại"
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            {categories.map((category) => (
              <MenuItem key={category.category[0]} value={category.category[0]}>
                {category.desVi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {currentCategory && (
          <>
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: "200px",
                cursor: "pointer",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  border: imageURL ? "none" : "2px dashed #ccc",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "8px",
                  backgroundColor: "transparent",
                  transition: "background-color 0.3s",
                  "&:hover": {
                    backgroundColor: imageURL
                      ? "rgba(0, 0, 0, 0.5)"
                      : "#f1f1f1",
                  },
                }}
                onClick={handleBoxClick}
              >
                {uploading ? (
                  <CircularProgress size={24} />
                ) : (
                  <>
                    {imageURL ? (
                      <Box
                        sx={{
                          position: "relative",
                          width: "100%",
                          height: "100%",
                          borderRadius: "8px",
                          overflow: "hidden",
                          backgroundColor: "transparent",
                        }}
                      >
                        <img
                          src={imageURL}
                          alt="Uploaded"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                            backgroundColor: "transparent",
                            borderRadius: "8px",
                          }}
                        />
                      </Box>
                    ) : (
                      <Typography variant="body1" color="textSecondary">
                        Nhấn vào đây để tải ảnh
                      </Typography>
                    )}
                  </>
                )}
                <input
                  ref={fileInputRef}
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </Box>
            </Box>
            <TextField
              label="Thể loại"
              value={newCategory}
              sx={{ mt: 2 }}
              onChange={(e) => setNewCategory(e.target.value)}
              fullWidth
            />
            <TextField
              label="Tên tiếng Việt"
              fullWidth
              margin="normal"
              value={nameVi}
              onChange={(e) => setNameVi(e.target.value)}
            />
            <TextField
              label="Tên tiếng Anh"
              fullWidth
              margin="normal"
              value={nameEn}
              onChange={(e) => setNameEn(e.target.value)}
            />
            <TextField
              label="Tên tiếng Nhật"
              fullWidth
              margin="normal"
              value={nameJp}
              onChange={(e) => setNameJp(e.target.value)}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Hủy</Button>
        <Button
          onClick={handleUpdateCategory}
          variant="contained"
          color="primary"
        >
          Lưu
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCategoryModal;
