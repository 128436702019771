import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import { getDailyRevenue } from "../service";

const Chart: React.FC<{
  branch: string;
  startDate: string;
  endDate: string;
}> = ({ branch, startDate, endDate }) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const [chartData, setChartData] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getDailyRevenue([branch], startDate, endDate);

        const branchData = data.map((item) => [
          new Date(item.date).getTime(),
          item.revenue,
        ]);

        setChartData([
          {
            name: `Chi nhánh ${
              branch === "duongkhue" ? "Dương Khuê" : "Phạm Hùng"
            }`,
            data: branchData,
            color: branch === "duongkhue" ? "#0000ff" : "#ff0000",
          },
        ]);
      } catch (error) {
        console.error(`Error fetching revenue data for ${branch}:`, error);
      }
    };

    fetchData();
  }, [branch, startDate, endDate]);

  useEffect(() => {
    if (chartRef.current && chartData.length > 0) {
      Highcharts.chart(chartRef.current, {
        title: {
          text: `Biểu đồ doanh thu - Chi nhánh ${
            branch === "duongkhue" ? "Dương Khuê" : "Phạm Hùng"
          }`,
        },
        xAxis: {
          type: "datetime",
          title: {
            text: "Ngày",
          },
          crosshair: {
            width: 1,
            color: "#333",
            dashStyle: "Dash",
          },
        },
        yAxis: {
          title: {
            text: "Biểu giá (VND)",
          },
          type: "logarithmic",
          crosshair: true,
        },
        series: chartData.map((data) => ({
          ...data,
          type: "line",
          color: {
            linearGradient: { x1: 0, x2: 0, y1: 1, y2: 0 },
            stops: [
              [0, data.color],
              [1, "#f8e50a"],
            ],
          },
          shadow: {
            color: "rgba(0, 0, 0, 0.3)",
            offsetX: 0,
            offsetY: 5,
            opacity: 0.5,
            width: 10,
          },
        })),
        tooltip: {
          xDateFormat: "%Y-%m-%d",
          shared: true,
          pointFormat: "{series.name}: <b>{point.y} VND</b><br/>",
        },
      });
    }
  }, [chartData]);

  return <div ref={chartRef}></div>;
};

export default Chart;
