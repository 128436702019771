// src/components/Sidebar.tsx
import React, { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FaBars, FaHome, FaTachometerAlt } from "react-icons/fa";
import logo from "../assets/images/Logo-DonChan-web.png";
import { useNavigate, useLocation } from "react-router-dom";
import { PiBowlFoodFill } from "react-icons/pi";

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [mobileOpen, setMobileOpen] = useState(false);

  const menuItems = [
    { text: "Trang chủ", icon: <FaHome fontSize={20} />, path: "/" },

    {
      text: "Món ăn",
      icon: <PiBowlFoodFill fontSize={20} />,
      path: "/list/food",
    },
    {
      text: "Đặt hàng",
      icon: <FaTachometerAlt fontSize={20} />,
      path: "/list/order",
    },
  ];

  const handleLogout = () => {
    localStorage.clear();
    navigate("/auth/login");
  };

  const toggleMobileDrawer = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuItemClick = (path: string) => {
    navigate(path);
    if (isMobile) {
      setMobileOpen(false);
    }
  };

  const drawerContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Box sx={{ padding: 2 }}>
          <img src={logo} alt="logo" width={200} />
        </Box>
        <List>
          {menuItems.map((item, index) => (
            <ListItem
              key={index}
              onClick={() => handleMenuItemClick(item.path)}
            >
              <ListItemButton
                sx={{
                  backgroundColor:
                    location.pathname === item.path ? "#FF1919" : "transparent",
                  color: location.pathname === item.path ? "#fff" : "#333",
                  transition: "0.2s ease",
                  borderRadius: 2,
                  "&:hover": { backgroundColor: "#FFBABA" },
                }}
              >
                <ListItemIcon
                  sx={{
                    color: location.pathname === item.path ? "#fff" : "#333",
                    "&:hover": { color: "#fff" },
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body1" fontWeight={500}>
                    {item.text}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Box>

      <Box sx={{ p: 2 }}>
        <Button
          variant="contained"
          color="error"
          fullWidth
          sx={{ fontWeight: 550 }}
          onClick={handleLogout}
        >
          Đăng xuất
        </Button>
      </Box>
    </Box>
  );

  return (
    <>
      {isMobile && (
        <AppBar position="fixed" sx={{ bgcolor: "#fff", boxShadow: "none" }}>
          <Stack direction="row" gap={10} alignItems="center">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleMobileDrawer}
              >
                <FaBars color="#333" />
              </IconButton>
            </Toolbar>
            <img src={logo} alt="logo" width={150} />
          </Stack>
        </AppBar>
      )}

      {!isMobile && (
        <Drawer
          variant="permanent"
          sx={{
            width: 240,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: 240,
              boxSizing: "border-box",
            },
          }}
        >
          {drawerContent}
        </Drawer>
      )}

      {isMobile && (
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={toggleMobileDrawer}
          sx={{
            "& .MuiDrawer-paper": { width: 240, boxSizing: "border-box" },
          }}
        >
          {drawerContent}
        </Drawer>
      )}
    </>
  );
};

export default Sidebar;
