import axios, { AxiosRequestConfig } from "axios";
import { useToken } from "src/utils/token";

export const getBranchConfig = async (
  type: string,
  token: string | null
): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/api/config/list/branch?type=${type}`;

  if (!token) {
    throw new Error("Token is required for API requests");
  }

  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": token,
      },
    };
    const response = await axios.get(url, axiosConfig);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch branch config:", error);
    throw error;
  }
};

export const uploadImages = async (
  file: File,
  headers: AxiosRequestConfig["headers"]
): Promise<any> => {
  const TIMEOUT_DURATION = 30 * 60 * 1000;
  let uploadTimeout: NodeJS.Timeout | null = null;

  try {
    const formData = new FormData();
    formData.append("file[]", file);

    const config: AxiosRequestConfig = {
      method: "post",
      url: `https://media.exam24h.com/upload-media?callback=${process.env.REACT_APP_API_URL}/api/media/create`,
      headers,
      data: formData,
      timeout: TIMEOUT_DURATION,
    };

    uploadTimeout = setTimeout(() => {
      console.error("Upload timed out");
      throw new Error("Upload timed out");
    }, TIMEOUT_DURATION);

    const response = await axios.request(config);

    clearTimeout(uploadTimeout);

    if (
      response.data &&
      response.data[0] &&
      response.data[0].callback &&
      response.data[0].callback._id &&
      response.data[0].src
    ) {
      return { id: response.data[0].callback._id, src: response.data[0].src };
    } else {
      throw new Error("Upload failed: Invalid response");
    }
  } catch (error) {
    if (uploadTimeout) {
      clearTimeout(uploadTimeout);
    }
    throw new Error(`Upload failed: ${error.message}`);
  }
};

export const createBranch = async (
  branchData: { type: string; option_content: string },
  token: string
): Promise<any> => {
  const axiosConfig = {
    headers: {
      "X-Authorization": token,
    },
  };

  const url = process.env.REACT_APP_API_URL + "/api/config/admin-update";

  try {
    const response = await axios.post(url, branchData, axiosConfig);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to create category: ${error.message}`);
  }
};

export const updateBranch = async (
  branchData: {
    type: string;
    option_content: string;
  },
  token: string
): Promise<any> => {
  const axiosConfig = {
    headers: {
      "X-Authorization": token,
    },
  };

  const url = process.env.REACT_APP_API_URL + "/api/config/admin-update";
  try {
    const response = await axios.post(url, branchData, axiosConfig);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to create category: ${error.message}`);
  }
};
