import axios, { AxiosRequestConfig } from "axios";

export const getListFood = async (
  sortOrder: "ASC" | "DESC" = "ASC",
  category?: string,
  branch?: string
): Promise<any> => {
  const url = `${
    process.env.REACT_APP_API_URL
  }/api/product/list?order_by=${sortOrder}${
    category ? `&category[0]=${category}` : ""
  }${branch ? `&branch=${branch}` : ""}`;

  try {
    return await axios.get(url);
  } catch (error) {
    throw new Error("Failed to fetch product data");
  }
};

export const getConfigCategory = async (branchKey?: string): Promise<any> => {
  const endpoint = branchKey
    ? `/api/config/list/category_${branchKey}`
    : `/api/config/list/category`;
  const url = process.env.REACT_APP_API_URL + endpoint;

  try {
    return await axios.get(url);
  } catch (error) {
    throw new Error("Failed to fetch category data");
  }
};

export const getListToppings = async (id: string): Promise<any> => {
  const url =
    process.env.REACT_APP_API_URL +
    `/api/product/list-topping?product_id=${id}`;
  try {
    return await axios.get(url);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const createFood = async (
  foodData: {
    name: string;
    description: string;
    category: string[];
    // branch: string;
    url: string;
    variants: Array<{
      size: string;
      price: number;
      quantity: number;
    }>;
  },
  token?: string
): Promise<any> => {
  const axiosConfig = {
    headers: {
      "X-Authorization": token,
    },
  };

  const url = process.env.REACT_APP_API_URL + "/api/product/create";
  try {
    const response = await axios.post(url, foodData, axiosConfig);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to create food: ${error.message}`);
  }
};

export const createCategory = async (
  categoryData: {
    type: string;
    option_content: string;
  },
  token?: string
): Promise<any> => {
  const axiosConfig = {
    headers: {
      "X-Authorization": token,
    },
  };

  const url = process.env.REACT_APP_API_URL + "/api/config/admin-update";
  try {
    const response = await axios.post(url, categoryData, axiosConfig);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to create category: ${error.message}`);
  }
};

export const updateFood = async (
  foodData: {
    _id: string;
    name: string;
    description: string;
    category: string[];
    url: string;
    variants: Array<{
      size: string;
      price: number;
      quantity: number;
    }>;
  },
  token?: string
): Promise<any> => {
  const axiosConfig = {
    headers: {
      "X-Authorization": token,
    },
  };

  const url = `${process.env.REACT_APP_API_URL}/api/product/update`;

  try {
    const response = await axios.patch(url, foodData, axiosConfig);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to update food: ${error.message}`);
  }
};

export const uploadImages = async (
  file: File,
  headers: AxiosRequestConfig["headers"]
): Promise<any> => {
  const TIMEOUT_DURATION = 30 * 60 * 1000;
  let uploadTimeout: NodeJS.Timeout | null = null;

  try {
    const formData = new FormData();
    formData.append("file[]", file);

    const config: AxiosRequestConfig = {
      method: "post",
      url: `https://media.exam24h.com/upload-media?callback=${process.env.REACT_APP_API_URL}/api/media/create`,
      headers,
      data: formData,
      timeout: TIMEOUT_DURATION,
    };

    uploadTimeout = setTimeout(() => {
      console.error("Upload timed out");
      throw new Error("Upload timed out");
    }, TIMEOUT_DURATION);

    const response = await axios.request(config);

    clearTimeout(uploadTimeout);

    if (
      response.data &&
      response.data[0] &&
      response.data[0].callback &&
      response.data[0].callback._id &&
      response.data[0].src
    ) {
      return { id: response.data[0].callback._id, src: response.data[0].src };
    } else {
      throw new Error("Upload failed: Invalid response");
    }
  } catch (error) {
    if (uploadTimeout) {
      clearTimeout(uploadTimeout);
    }
    throw new Error(`Upload failed: ${error.message}`);
  }
};

export const deleteFood = async (id: string, token?: string): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/api/product/delete/${id}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": token,
      },
    };

    return await axios.delete(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
