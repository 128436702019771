import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import React, { useState, useEffect } from "react";
import { HiTicket } from "react-icons/hi2";
import CreateCouponModal from "./components/CreateCouponModal";
import PromoCard from "./components/PromoCard";
import { Coupon, CouponUpdate } from "./interface";
import {
  createCoupon,
  deleteCoupon,
  getListCoupon,
  updateCoupon,
} from "./service";
import { useSnackbar } from "src/components/CustomSnackbar";
import EditCouponModal from "./components/EditCouponModal";
import { useToken } from "src/utils/token";

const CouponList = () => {
  const { showSnackbar } = useSnackbar();
  const token = useToken();
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [couponData, setCouponData] = useState({
    title: "",
    type: "",
    total: "",
    promotion: "",
    promotion_type: "percentage",
    expired: "",
    availableAt: "",
    code: "",
    token: "",
  });
  const [selectedCoupon, setSelectedCoupon] = useState<CouponUpdate | null>(
    null
  );
  const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);
  const [couponDataUpdate, setCouponDataUpdate] = useState<
    Partial<CouponUpdate>
  >({});
  const [deleting, setDeleting] = useState<boolean>(false);

  const fetchCoupons = async () => {
    try {
      const response = await getListCoupon(token || "");
      setCoupons(response.data);
    } catch (error) {
      console.error("Error fetching coupons:", error);
    }
  };

  useEffect(() => {
    fetchCoupons();
  }, []);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModalUpdate = (coupon: Coupon) => {
    if (!token) {
      console.error("Token is missing");
      return;
    }
    const updatedCoupon: CouponUpdate = {
      ...coupon,
      expired: coupon.expired ? coupon.expired.split("T")[0] : "",
      availableAt: coupon.availableAt ? coupon.availableAt.split("T")[0] : "",
      token,
    };

    setSelectedCoupon(updatedCoupon);
    setCouponDataUpdate(updatedCoupon);
    setOpenModalEdit(true);
  };

  const handleCloseModalUpdate = () => {
    setOpenModalEdit(false);
    setCouponDataUpdate({});
    setSelectedCoupon(null);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCouponData({ ...couponData, [name]: value });
  };

  const handleChangeUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setCouponDataUpdate((prev) => ({
      ...prev,
      [name]:
        name === "code"
          ? value.toUpperCase()
          : name === "total" || name === "promotion"
          ? Number(value)
          : value,
    }));
  };

  const handleSubmitUpdate = async () => {
    if (!couponDataUpdate._id) {
      console.error("No coupon ID provided for update");
      return;
    }

    try {
      const updatedCode = (couponDataUpdate.code || "").toUpperCase();
      await updateCoupon(
        couponDataUpdate._id,
        couponDataUpdate.title || "",
        couponDataUpdate.type || "",
        couponDataUpdate.total?.toString() || "0",
        couponDataUpdate.promotion || 0,
        couponDataUpdate.promotion_type || "",
        couponDataUpdate.expired || "",
        couponDataUpdate.availableAt || "",
        couponDataUpdate.code || "",
        token || ""
      );
      fetchCoupons();
      handleCloseModalUpdate();
      showSnackbar("Cập nhật mã giảm giá thành công!", "success");
    } catch (error) {
      showSnackbar("Cập nhật mã giảm giá thất bại.", "error");
    }
  };

  const handleSubmit = async () => {
    const promotionValue = parseFloat(
      String(couponData.promotion).replace("%", "").trim()
    ).toString();
    try {
      await createCoupon(
        couponData.title,
        "product",
        couponData.total,
        promotionValue,
        couponData.promotion_type,
        couponData.expired,
        couponData.availableAt,
        couponData.code,
        token || ""
      );

      const response = await getListCoupon(token || "");
      setCoupons(response.data);

      handleCloseModal();
      showSnackbar("Tạo mã giảm giá thành công!", "success");
    } catch (error) {
      showSnackbar("Tạo mã giảm giá thất bại.", "error");
    }
  };

  const handleDeleteCoupon = async (couponId: string) => {
    if (!couponId || !token) {
      showSnackbar("Có lỗi xảy ra", "error");
      return;
    }

    if (window.confirm("Bạn có chắc xóa bõ mã giảm giá này không?")) {
      try {
        await deleteCoupon(couponId, token);
        fetchCoupons();
        showSnackbar("Xóa mã giảm giá thành công!", "success");
        handleCloseModalUpdate();
      } catch (error) {
        console.error("Error deleting coupon:", error);
        showSnackbar("Xóa mã giảm giá thất bại.", "error");
      }
    }
  };

  const isMobile = useMediaQuery("(max-width: 600px)");
  const isMobileSmall = useMediaQuery("(max-width: 375px)");
  const isIphone14 = useMediaQuery("(max-width: 430px)");

  return (
    <Box
      sx={{ mt: isMobile ? 5 : "none" }}
      width="100%"
      p={2}
      pt={4}
      maxWidth={1200}
      margin="0 auto"
    >
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        gap={isMobile ? 2 : 0}
        mb={2}
      >
        <Typography variant="h5" fontWeight={550}>
          Danh sách mã giảm giá
        </Typography>
        <Stack direction="row" gap={2} alignItems="center">
          <Button
            variant="contained"
            onClick={handleOpenModal}
            sx={{ textTransform: "none", fontWeight: 550, bgcolor: "#FF1919" }}
          >
            Tạo mã giảm giá
          </Button>
        </Stack>
      </Stack>

      <Box
        display="grid"
        gridTemplateColumns={isMobile ? "1fr" : " 1fr 1fr 1fr"}
        gap={2}
      >
        {coupons.map((coupon) => (
          <Box key={coupon._id} onClick={() => handleOpenModalUpdate(coupon)}>
            <PromoCard coupon={coupon} />
          </Box>
        ))}
      </Box>

      <CreateCouponModal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        couponData={couponData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />

      <EditCouponModal
        open={openModalEdit}
        onClose={handleCloseModalUpdate}
        couponData={couponDataUpdate}
        onChange={handleChangeUpdate}
        onSubmit={handleSubmitUpdate}
        setCouponData={setCouponData}
        onDelete={() => handleDeleteCoupon(couponDataUpdate._id!)}
      />
    </Box>
  );
};

export default CouponList;
