import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  TextField,
  Typography,
  Box,
  Container,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { login } from "./service";
import { getToken } from "src/utils/token";

import logo from "./assets/images/Logo-DonChan-chuan.png";

const Login: React.FC = () => {
  const [userEmail, setUserEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("token");

    const token = getToken();
    if (token) {
      navigate("/list/food");
    }
  }, [navigate]);

  const handleLogin = async () => {
    setIsLoading(true);
    setError("");
    try {
      const response = await login(userEmail, password);
      const token = response.headers["x-authorization"];
      if (token) {
        localStorage.setItem("token", token);
        navigate("/list/food");
      } else {
        setError("Token not found in response");
      }
    } catch (err) {
      setError("Invalid credentials");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          padding: 4,
          width: "100%",
          borderRadius: "10px",
          textAlign: "center",
        }}
      >
        <img src={logo} alt="logo" width={200} />
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value)}
        />
        <TextField
          label="Mật khẩu"
          variant="outlined"
          fullWidth
          margin="normal"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {error && <Typography color="error">{error}</Typography>}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleLogin}
          sx={{
            height: 50,
            bgcolor: "#FF1919",
            textTransform: "none",
            fontWeight: 550,
            fontSize: 16,
            mt: 2,
          }}
        >
          Đăng nhập
        </Button>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </Container>
  );
};

export default Login;
