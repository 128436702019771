import axios from "axios";


export const login = async (
    user_email: string,
    user_password: string
): Promise<any> => {
    const requestData = {
        user_email,
        user_password
    };

    const url = process.env.REACT_APP_API_URL + "/api/user/login/password";
    return axios.post(url, requestData);
};
