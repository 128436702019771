import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { BranchCardProps, BranchIT } from "../interface";
import { uploadImages } from "../service";
import { useToken } from "src/utils/token";
import { listBanks } from "src/utils/bank";

export const BranchCard = ({
  branches,
  handleUpdate,
  setSelectedBranchIndex,
}: BranchCardProps) => {
  const theme = useTheme();
  const token = useToken();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openModal, setOpenModal] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState<BranchIT | null>(null);
  const [step, setStep] = useState(1);

  const [uploading, setUploading] = useState(false);
  const [imageURL, setImageURL] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleOpenModal = (branch: BranchIT, index: number) => {
    setSelectedBranch(branch);
    setSelectedBranchIndex(index);
    setImageURL(branch.image);
    setOpenModal(true);
    setStep(1);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleNextStep = () => setStep((prev) => prev + 1);
  const handlePreviousStep = () => setStep((prev) => prev - 1);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploading(true);
      try {
        const headers = {
          "X-Authorization": token,
          "Content-Type": "multipart/form-data",
        };

        const uploadedImage = await uploadImages(file, headers);
        setImageURL(uploadedImage.src);
      } catch (error) {
        console.error("Error uploading image:", error);
      } finally {
        setUploading(false);
      }
    }
  };

  const handleBranchDataChange = (key: string, value: string) => {
    setSelectedBranch((prev) => {
      if (prev) {
        const updatedBranch = {
          ...prev,
          [key]: value,
        };
        return updatedBranch;
      }
      return prev;
    });
  };

  const handleSubmit = async () => {
    if (selectedBranch) {
      try {
        const updatedBranch = {
          ...selectedBranch,
          image: imageURL || selectedBranch.image,
        };
        await handleUpdate(updatedBranch);
        setOpenModal(false);
      } catch (error) {
        console.error("Error updating branch:", error);
      }
    }
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns={isMobile ? "1fr" : "1fr 1fr 1fr"}
      gap={2}
    >
      {branches.map((branch, index) => (
        <Box
          key={index}
          position="relative"
          width="100%"
          height={280}
          sx={{
            cursor: "pointer",
            "&:hover .overlay": {
              bgcolor: "rgba(0, 0, 0, 0.7)",
              transition: "background-color 0.3s ease",
            },
          }}
          onClick={() => handleOpenModal(branch, index)}
        >
          <img
            src={branch.image}
            alt={branch.title}
            width="100%"
            height="100%"
            style={{
              objectFit: "cover",
              borderRadius: "10px",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            }}
          />
          <Box
            className="overlay"
            sx={{
              position: "absolute",
              top: "210px",
              bgcolor: "rgba(0, 0, 0, 0.5)",
              width: "100%",
              height: 70,
              p: 1,
              borderRadius: "0px 0px 10px 10px",
              transition: "background-color 0.3s ease",
            }}
          >
            <Typography variant="body2" color="#fff">
              Chi nhánh
            </Typography>
            <Typography variant="body1" color="#fff" fontWeight={600}>
              {branch.title}
            </Typography>
          </Box>
        </Box>
      ))}

      {selectedBranch && (
        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            sx={{
              width: "100%",
              maxWidth: 400,
              bgcolor: "background.paper",
              borderRadius: 2,
              padding: 3,
              boxShadow: 24,
              margin: "auto",
              top: "20%",
              position: "relative",
            }}
          >
            <Typography variant="h6" mb={2}>
              {step === 1
                ? " Chỉnh sửa chi nhánh"
                : "Xác nhận thông tin chi nhánh"}
            </Typography>
            {step === 1 && (
              <>
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    height: "200px",
                    cursor: "pointer",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      border: imageURL ? "none" : "2px dashed #ccc",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "8px",
                      backgroundColor: "#f9f9f9",
                      transition: "background-color 0.3s",
                      "&:hover": {
                        backgroundColor: imageURL
                          ? "rgba(0, 0, 0, 0.5)"
                          : "#f1f1f1",
                      },
                    }}
                    onClick={() => fileInputRef.current?.click()}
                  >
                    {uploading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <>
                        {imageURL ? (
                          <Box
                            sx={{
                              position: "relative",
                              width: "100%",
                              height: "100%",
                              borderRadius: "8px",
                              overflow: "hidden",
                            }}
                          >
                            <img
                              src={imageURL}
                              alt="Uploaded"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                                borderRadius: "8px",
                              }}
                            />
                          </Box>
                        ) : (
                          <Typography variant="body1" color="textSecondary">
                            Nhấn vào đây để tải ảnh
                          </Typography>
                        )}
                      </>
                    )}

                    <input
                      ref={fileInputRef}
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </Box>
                </Box>
                <TextField
                  label="Key"
                  variant="outlined"
                  fullWidth
                  value={selectedBranch.key}
                  onChange={(e) =>
                    handleBranchDataChange("key", e.target.value)
                  }
                  margin="normal"
                />
                <TextField
                  label="Tên chi nhánh"
                  variant="outlined"
                  fullWidth
                  value={selectedBranch.title}
                  onChange={(e) =>
                    handleBranchDataChange("title", e.target.value)
                  }
                  margin="normal"
                />
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  spacing={2}
                  mt={3}
                >
                  <Button onClick={handleCloseModal}>Hủy</Button>
                  <Button
                    onClick={handleNextStep}
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      fontWeight: 550,
                      bgcolor: "#FF1919",
                    }}
                  >
                    Tiếp theo
                  </Button>
                </Stack>
              </>
            )}

            {step === 2 && (
              <>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Ngân hàng</InputLabel>
                  <Select
                    value={selectedBranch.bin || ""}
                    onChange={(e) => {
                      const selectedBank = listBanks.find(
                        (bank) => bank.bin === e.target.value
                      );
                      handleBranchDataChange("bin", e.target.value);
                      if (selectedBank) {
                        handleBranchDataChange("logo", selectedBank.logo);
                        handleBranchDataChange(
                          "shortName",
                          selectedBank.shortName
                        );
                      }
                    }}
                    label="Ngân hàng"
                  >
                    {listBanks.map((bank) => (
                      <MenuItem key={bank.bin} value={bank.bin}>
                        {`Ngân hàng ${bank.shortName}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  label="Số điện thoại"
                  variant="outlined"
                  fullWidth
                  value={selectedBranch.phone}
                  onChange={(e) =>
                    handleBranchDataChange("phone", e.target.value)
                  }
                  margin="normal"
                />
                <TextField
                  label="Số tài khoản"
                  variant="outlined"
                  fullWidth
                  value={selectedBranch.card_id}
                  onChange={(e) =>
                    handleBranchDataChange("card_id", e.target.value)
                  }
                  margin="normal"
                />
                <TextField
                  label="Tên chủ thẻ"
                  variant="outlined"
                  fullWidth
                  value={selectedBranch.card_name}
                  onChange={(e) =>
                    handleBranchDataChange("card_name", e.target.value)
                  }
                  margin="normal"
                />
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  spacing={2}
                  mt={3}
                >
                  <Button onClick={handlePreviousStep}>Quay lại</Button>
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      fontWeight: 550,
                      bgcolor: "#FF1919",
                    }}
                  >
                    Cập nhật
                  </Button>
                </Stack>
              </>
            )}
          </Box>
        </Modal>
      )}
    </Box>
  );
};
