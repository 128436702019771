import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  CircularProgress,
  Chip,
  InputAdornment,
  Stack,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
} from "@mui/material";
import { IoClose } from "react-icons/io5";
import {
  createCategory,
  createFood,
  getConfigCategory,
  uploadImages,
} from "../service";
import { CreateCategoryModalProps } from "../interface";
import { useToken } from "src/utils/token";
import { BranchIT } from "src/pages/Branch/interface";
import { getBranchConfig } from "src/pages/Home/service";
import { useSelector } from "react-redux";
import { RootState } from "src/redux-persist/store";
import { useSnackbar } from "src/components/CustomSnackbar";

const CreateCategoryModal: React.FC<CreateCategoryModalProps> = ({
  open,
  onClose,
}) => {
  const token = useToken();
  const { showSnackbar } = useSnackbar();
  const userRole = useSelector((state: RootState) => state.user.userRole);
  const userBranch = useSelector((state: RootState) => state.user.userBranch);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploading, setUploading] = useState(false);
  const [imageURL, setImageURL] = useState<string | null>(null);
  const [category, setCategory] = useState<string>("");
  const [nameVi, setNameVi] = useState<string>("");
  const [nameEn, setNameEn] = useState<string>("");
  const [nameJp, setNameJp] = useState<string>("");
  const [branches, setBranches] = useState<BranchIT[]>([]);
  const [branchInput, setBranchInput] = useState<string>(userBranch || "");

  const [loading, setLoading] = useState<boolean>(true);
  const [categories, setCategories] = useState<
    {
      title: string;
      category: string[];
      _id: string;
      desVi: string;
      desEn: string;
      desJp: string;
      image: string;
    }[]
  >([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getConfigCategory();
        const data = response.data?.config?.option_content || [];
        const mappedCategories = data.map((item: any) => ({
          ...item,
          branchKey: item.branchKey || "",
        }));
        setCategories(mappedCategories);
      } catch (error) {
        console.error("Error fetching category data:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const data = await getBranchConfig("branch", token || "");
        const branchData = data.config.option_content;
        setBranches(branchData);

        if (branchData.length > 0) {
          setBranchInput(branchData[0].key);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching branches:", error);
        setLoading(false);
      }
    };

    fetchBranches();
  }, []);

  const handleBoxClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      console.log("Selected file:", selectedFile);

      try {
        setUploading(true);

        const headers = {
          "X-Authorization": token,
        };

        const uploadedImageUrl = await uploadImages(selectedFile, headers);

        setImageURL(uploadedImageUrl.src);
        console.log("Image uploaded successfully:", uploadedImageUrl);
      } catch (error) {
        console.error("Error uploading image:", error);
        alert("Failed to upload image");
      } finally {
        setUploading(false);
      }
    }
  };

  const handleSubmit = async () => {
    if (!branchInput || branchInput === "") {
      showSnackbar("Bạn phải chọn 1 chi nhánh", "warning");
      return;
    }

    try {
      const response = await getConfigCategory(branchInput);
      const currentOptionContent = response.data?.config?.option_content || [];

      const newCategory = {
        category: [category],
        desVi: nameVi,
        desEn: nameEn,
        desJp: nameJp,
        image: imageURL || "",
      };

      const updatedCategories = [...currentOptionContent, newCategory];

      const categoryData = {
        type: `category_${branchInput}`,
        option_content: JSON.stringify(updatedCategories),
      };

      await createCategory(categoryData, token || "");
      showSnackbar("Tạo thể loại thành công!", "success");
      onClose();
    } catch (error) {
      console.error("Error creating category:", error);
      showSnackbar("Tạo thể loại thất bại.", "error");
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="create-food-modal-title"
      aria-describedby="create-food-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            id="create-food-modal-title"
            fontWeight={550}
          >
            Tạo thể loại mới
          </Typography>
          <IconButton onClick={onClose}>
            <IoClose />
          </IconButton>
        </Box>

        <Box
          component="form"
          sx={{ mt: 2, mb: 2 }}
          onSubmit={(e) => e.preventDefault()}
        >
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "200px",
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                border: imageURL ? "none" : "2px dashed #ccc",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                backgroundColor: "transparent",
                transition: "background-color 0.3s",
                "&:hover": {
                  backgroundColor: imageURL ? "rgba(0, 0, 0, 0.5)" : "#f1f1f1",
                },
              }}
              onClick={handleBoxClick}
            >
              {uploading ? (
                <CircularProgress size={24} />
              ) : (
                <>
                  {imageURL ? (
                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                        borderRadius: "8px",
                        overflow: "hidden",
                        backgroundColor: "transparent",
                      }}
                    >
                      <img
                        src={imageURL}
                        alt="Uploaded"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                          backgroundColor: "transparent",
                          borderRadius: "8px",
                        }}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          "&:hover::before": {
                            content: '"Change Image"',
                            position: "absolute",
                            color: "#fff",
                            fontSize: "18px",
                            fontWeight: "bold",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            padding: "10px 20px",
                            borderRadius: "4px",
                          },
                        }}
                      />
                    </Box>
                  ) : (
                    <Typography variant="body1" color="textSecondary">
                      Nhấn vào đây để tải ảnh
                    </Typography>
                  )}
                </>
              )}
              <input
                ref={fileInputRef}
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </Box>
          </Box>
          {userRole === "super-admin" && (
            <FormControl
              fullWidth
              sx={{ flex: 1, minWidth: "120px", mb: 2, mt: 2 }}
            >
              <InputLabel>Chi nhánh</InputLabel>
              <Select
                value={branchInput || ""}
                onChange={(e) => setBranchInput(e.target.value)}
                label="Chi nhánh"
              >
                {branches.map((branch) => (
                  <MenuItem key={branch.key} value={branch.key}>
                    {branch.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <TextField
            fullWidth
            size="small"
            label="Thể loại"
            variant="outlined"
            sx={{ mb: 2 }}
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />

          <TextField
            fullWidth
            size="small"
            label="Tên tiếng Việt"
            variant="outlined"
            sx={{ mb: 2 }}
            value={nameVi}
            onChange={(e) => setNameVi(e.target.value)}
          />

          <TextField
            fullWidth
            size="small"
            label="Tên tiếng Anh"
            variant="outlined"
            sx={{ mb: 2 }}
            value={nameEn}
            onChange={(e) => setNameEn(e.target.value)}
          />

          <TextField
            fullWidth
            size="small"
            label="Tên tiếng Nhật"
            variant="outlined"
            sx={{ mb: 2 }}
            value={nameJp}
            onChange={(e) => setNameJp(e.target.value)}
          />

          <Button
            variant="contained"
            fullWidth
            sx={{ textTransform: "none", fontWeight: 550, bgcolor: "#FF1919" }}
            onClick={handleSubmit}
          >
            Tạo thể loại
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateCategoryModal;
