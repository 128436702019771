import React, { ChangeEvent } from "react";
import {
  Modal,
  Box,
  Typography,
  Stack,
  TextField,
  Button,
  InputAdornment,
} from "@mui/material";
import { CreateCouponModalProps } from "../interface";

const CreateCouponModal: React.FC<CreateCouponModalProps> = ({
  openModal,
  handleCloseModal,
  couponData,
  handleChange,
  handleSubmit,
}) => {
  return (
    <Modal open={openModal} onClose={handleCloseModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxWidth: "600px",
          bgcolor: "white",
          borderRadius: 2,
          boxShadow: 24,
          p: 3,
        }}
      >
        <Typography variant="h6" mb={2}>
          Tạo mã giảm giá
        </Typography>
        <Stack spacing={2}>
          <Stack
            direction="row"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
            gap={1}
          >
            <TextField
              label="Tiêu đề"
              name="title"
              size="small"
              value={couponData.title}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="Mã giảm giá"
              name="code"
              size="small"
              value={couponData.code}
              onChange={handleChange}
              fullWidth
            />
          </Stack>

          <Stack
            direction="row"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
            gap={1}
          >
            <TextField
              label="Tổng số vé"
              name="total"
              size="small"
              value={couponData.total}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="Giảm giá"
              name="promotion"
              size="small"
              value={couponData.promotion}
              onChange={handleChange}
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Stack>

          <Stack
            direction="row"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
            gap={1}
          >
            <TextField
              label="Ngày bắt đầu"
              name="availableAt"
              size="small"
              type="date"
              value={couponData.availableAt}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Ngày hết hạn"
              name="expired"
              size="small"
              type="date"
              value={couponData.expired}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2} mt={2} justifyContent="flex-end">
          <Button variant="outlined" onClick={handleCloseModal}>
            Hủy
          </Button>
          <Button variant="contained" onClick={handleSubmit}>
            Tạo mã giảm giá
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default CreateCouponModal;
