import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  CircularProgress,
  Chip,
  InputAdornment,
  Stack,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { IoClose } from "react-icons/io5";
import { createFood, getConfigCategory, uploadImages } from "../service";

import { CreateFoodModalProps, Variant } from "../interface";
import { useSnackbar } from "src/components/CustomSnackbar";
import { useToken } from "src/utils/token";
import { useSelector } from "react-redux";
import { RootState } from "src/redux-persist/store";
import { BranchIT } from "src/pages/Branch/interface";
import { getBranchConfig } from "src/pages/Home/service";

const CreateFoodModal: React.FC<CreateFoodModalProps> = ({
  open,
  onClose,
  setFoods,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { showSnackbar } = useSnackbar();
  const token = useToken();
  const userRole = useSelector((state: RootState) => state.user.userRole);
  const userBranch = useSelector((state: RootState) => state.user.userBranch);

  const [uploading, setUploading] = useState(false);
  const [imageURL, setImageURL] = useState<string | null>(null);
  const [foodName, setFoodName] = useState<string>("");
  const [branchInput, setBranchInput] = useState<string>(userBranch || "");
  const [branches, setBranches] = useState<BranchIT[]>([]);
  const [description, setDescription] = useState<string>("");
  const [categoryInput, setCategoryInput] = useState<string>("");
  const [categories, setCategories] = useState<
    { title: string; category: string[]; _id: string }[]
  >([]);
  const [variants, setVariants] = useState<Variant[]>([
    { size: "S", price: 0, quantity: 0 },
    { size: "M", price: 0, quantity: 0 },
    { size: "L", price: 0, quantity: 0 },
  ]);

  const isMobile = useMediaQuery("(max-width: 600px)");
  const isMobileSmall = useMediaQuery("(max-width: 375px)");
  const isIphone14 = useMediaQuery("(max-width: 430px)");

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const data = await getBranchConfig("branch", token || "");
        setBranches(data.config.option_content);

        if (
          userRole === "super-admin" &&
          data.config.option_content.length > 0
        ) {
          const firstBranch = data.config.option_content[0].key;
          setBranchInput(firstBranch);
        }
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    };

    if (userRole === "super-admin") {
      fetchBranches();
    }
  }, [userRole, token]);

  useEffect(() => {
    if (!branchInput) return;

    const fetchCategories = async () => {
      try {
        const response = await getConfigCategory(branchInput);
        setCategories(response.data.config.option_content || []);

        setCategoryInput("");
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [branchInput]);

  const handleCategoryChange = (event: any) => {
    const { value } = event.target;
    setCategoryInput(value);
  };

  const handleVariantChange = (
    size: string,
    field: "price" | "quantity",
    value: string
  ) => {
    setVariants((prevVariants) => {
      return prevVariants.map((variant) => {
        if (variant.size === size) {
          return { ...variant, [field]: Number(value) };
        }
        return variant;
      });
    });
  };

  const handleBoxClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      console.log("Selected file:", selectedFile);

      try {
        setUploading(true);

        const headers = {
          "X-Authorization": token,
        };

        const uploadedImageUrl = await uploadImages(selectedFile, headers);

        setImageURL(uploadedImageUrl.src);
        console.log("Image uploaded successfully:", uploadedImageUrl);
      } catch (error) {
        console.error("Error uploading image:", error);
        alert("Failed to upload image");
      } finally {
        setUploading(false);
      }
    }
  };

  const handleCreateFood = async () => {
    if (userRole !== "super-admin" && !userBranch) {
      showSnackbar("Bạn không có quyền tạo món ăn", "error");
      return;
    }

    const selectedBranch =
      userRole === "super-admin" ? branchInput : userBranch;

    if (!selectedBranch) {
      showSnackbar("Vui lòng chọn chi nhánh để tạo món ăn", "error");
      return;
    }

    const foodData = {
      _id: new Date().getTime().toString(),
      name: foodName,
      description,
      branch: selectedBranch,
      url: imageURL || "",
      category: [categoryInput],
      variants: variants.filter(
        (variant) => variant.price > 0 && variant.quantity > 0
      ),
    };

    try {
      const response = await createFood(foodData, token || "");
      showSnackbar("Tạo món ăn thành công", "success");

      setFoodName("");
      setDescription("");
      setCategories([]);
      setVariants([
        { size: "S", price: 0, quantity: 0 },
        { size: "M", price: 0, quantity: 0 },
        { size: "L", price: 0, quantity: 0 },
      ]);
      setBranchInput(
        userRole === "super-admin" ? branches[0]?.key : userBranch || ""
      );
      setImageURL(null);

      onClose();
    } catch (error) {
      console.error("Error creating food:", error);
      showSnackbar("Tạo món ăn thất bại.", "error");
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="create-food-modal-title"
      aria-describedby="create-food-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            id="create-food-modal-title"
            fontWeight={550}
          >
            Tạo món ăn mới
          </Typography>
          <IconButton onClick={onClose}>
            <IoClose />
          </IconButton>
        </Box>

        <Box component="form" sx={{ mt: 2 }}>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "200px",
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                border: imageURL ? "none" : "2px dashed #ccc",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                backgroundColor: "#f9f9f9",
                transition: "background-color 0.3s",
                "&:hover": {
                  backgroundColor: imageURL ? "rgba(0, 0, 0, 0.5)" : "#f1f1f1",
                },
              }}
              onClick={handleBoxClick}
            >
              {uploading ? (
                <CircularProgress size={24} />
              ) : (
                <>
                  {imageURL ? (
                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                        borderRadius: "8px",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={imageURL}
                        alt="Uploaded"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                          borderRadius: "8px",
                        }}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          "&:hover::before": {
                            content: '"Change Image"',
                            position: "absolute",
                            color: "#fff",
                            fontSize: "18px",
                            fontWeight: "bold",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            padding: "10px 20px",
                            borderRadius: "4px",
                          },
                        }}
                      />
                    </Box>
                  ) : (
                    <Typography variant="body1" color="textSecondary">
                      Nhấn vào đây để tải ảnh
                    </Typography>
                  )}
                </>
              )}

              <input
                ref={fileInputRef}
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </Box>
          </Box>
          <TextField
            fullWidth
            size="small"
            label="Tên món ăn"
            variant="outlined"
            value={foodName}
            onChange={(e) => setFoodName(e.target.value)}
            sx={{ mb: 2, mt: 2 }}
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            gap={1}
            alignItems="center"
            sx={{ width: "100%", mb: 2 }}
          >
            {variants.map((variant) => (
              <Box key={variant.size} sx={{ flex: 1 }}>
                <TextField
                  fullWidth
                  size="small"
                  label={`Kích cỡ: ${variant.size}`}
                  placeholder="Giá tiền"
                  variant="outlined"
                  type="number"
                  sx={{ mb: 2 }}
                  value={variant.price || ""}
                  onChange={(e) =>
                    handleVariantChange(variant.size, "price", e.target.value)
                  }
                />
                <TextField
                  fullWidth
                  size="small"
                  label={`Số lượng: ${variant.size}`}
                  placeholder="Số lượng"
                  variant="outlined"
                  type="number"
                  value={variant.quantity || ""}
                  onChange={(e) =>
                    handleVariantChange(
                      variant.size,
                      "quantity",
                      e.target.value
                    )
                  }
                />
              </Box>
            ))}
          </Stack>
          {userRole === "super-admin" && (
            <FormControl fullWidth sx={{ flex: 1, minWidth: "120px", mb: 2 }}>
              <InputLabel>Chi nhánh</InputLabel>
              <Select
                value={branchInput}
                onChange={(e) => setBranchInput(e.target.value)}
                label="Chi nhánh"
              >
                {branches.map((branch) => (
                  <MenuItem key={branch.key} value={branch.key}>
                    {branch.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl fullWidth sx={{ flex: 1, minWidth: "120px", mb: 2 }}>
            <InputLabel id="category-select-label">Chọn thể loại</InputLabel>
            <Select
              labelId="category-select-label"
              value={categoryInput}
              onChange={handleCategoryChange}
              input={<OutlinedInput label="Chọn thể loại" />}
            >
              {categories.map((category: any) => (
                <MenuItem key={category._id} value={category.category[0]}>
                  {category.desVi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            size="small"
            label="Mô tả món ăn"
            variant="outlined"
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            sx={{ mb: 2 }}
          />

          <Button
            variant="contained"
            fullWidth
            sx={{ textTransform: "none", fontWeight: 550, bgcolor: "#FF1919" }}
            onClick={handleCreateFood}
          >
            Tạo món
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateFoodModal;
