import React from "react";
import {
  Box,
  Typography,
  Stack,
  TextField,
  Button,
  InputAdornment,
  Modal,
} from "@mui/material";
import { CouponUpdate } from "../interface";
import { IoTrashOutline } from "react-icons/io5";

interface EditCouponModalProps {
  open: boolean;
  onClose: () => void;
  couponData: Partial<CouponUpdate>;
  setCouponData: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  onDelete: () => void;
}

const EditCouponModal: React.FC<EditCouponModalProps> = ({
  open,
  onClose,
  couponData,
  onChange,
  onSubmit,
  onDelete,
  setCouponData,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxWidth: "600px",
          bgcolor: "white",
          borderRadius: 2,
          boxShadow: 24,
          p: 3,
        }}
      >
        <Stack
          direction="row"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant="h6">Edit Coupon</Typography>
          <IoTrashOutline
            fontSize={20}
            color="red"
            style={{ cursor: "pointer" }}
            onClick={onDelete}
          />
        </Stack>
        <Stack spacing={2}>
          <Stack
            direction="row"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
          >
            <TextField
              label="Tiêu đề"
              name="title"
              size="small"
              value={couponData.title || ""}
              onChange={onChange}
              fullWidth
            />
            <TextField
              label="Mã giảm giá"
              name="code"
              size="small"
              value={couponData.code || ""}
              onChange={onChange}
              fullWidth
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              label="Tổng số vé"
              name="total"
              size="small"
              type="number"
              value={couponData.total?.toString() || ""}
              onChange={onChange}
              fullWidth
            />
            <TextField
              label="Giảm giá"
              name="promotion"
              size="small"
              type="number"
              value={couponData.promotion?.toString() || ""}
              onChange={onChange}
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              label="Ngày bắt đầu"
              name="availableAt"
              size="small"
              type="date"
              value={couponData.availableAt || ""}
              onChange={onChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Ngày kết thúc"
              name="expired"
              size="small"
              type="date"
              value={couponData.expired || ""}
              onChange={onChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2} mt={2} justifyContent="flex-end">
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onSubmit}>
            Update
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default EditCouponModal;
