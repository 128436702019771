import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { IoCalendarOutline, IoClose } from "react-icons/io5";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { formatCurrency } from "src/utils/formatCurrency";
import { getListOrder, getListStatics } from "./service";
import Chart from "./components/Chart";
import { useLocation, useNavigate } from "react-router-dom";

const Home: React.FC = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [selectedStatus, setSelectedStatus] = useState("served");
  const [selectedBranch, setSelectedBranch] = useState("duongkhue");

  const [ordersDuongKhue, setOrdersDuongKhue] = useState<any[]>([]);
  const [ordersPhamHung, setOrdersPhamHung] = useState<any[]>([]);

  const [totalStaticsDuongKhue, setTotalStaticsDuongKhue] = useState({
    totalCount: 0,
    totalPrice: 0,
  });
  const [totalStaticsPhamHung, setTotalStaticsPhamHung] = useState({
    totalCount: 0,
    totalPrice: 0,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [selectedPeriod, setSelectedPeriod] = useState("thisMonth");
  const [showDatePicker, setShowDatePicker] = useState(false);

  const stackRef = useRef<HTMLDivElement | null>(null);

  const startOfThisMonth = dayjs().startOf("month");
  const endOfThisMonth = startOfThisMonth.endOf("month");

  const [selectedStartDate, setSelectedStartDate] = useState<Dayjs | null>(
    startOfThisMonth
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Dayjs | null>(
    endOfThisMonth
  );

  const isMobile = useMediaQuery("(max-width: 600px)");
  const isMobileSmall = useMediaQuery("(max-width: 375px)");
  const isIphone14 = useMediaQuery("(max-width: 430px)");

  const fetchOrdersAndStatics = async () => {
    setLoading(true);
    try {
      let startDate = selectedStartDate
        ? selectedStartDate.format("YYYY-MM-DD")
        : undefined;
      let endDate = selectedEndDate
        ? selectedEndDate.add(1, "day").format("YYYY-MM-DD")
        : undefined;

      const [orderResponseDuongKhue, orderResponsePhamHung] = await Promise.all(
        [
          getListOrder(
            "duongkhue",
            rowsPerPage,
            page + 1,
            selectedStatus,
            startDate,
            endDate
          ),
          getListOrder(
            "phamhung",
            rowsPerPage,
            page + 1,
            selectedStatus,
            startDate,
            endDate
          ),
        ]
      );

      const [staticsResponseDuongKhue, staticsResponsePhamHung] =
        await Promise.all([
          getListStatics(
            "duongkhue",
            rowsPerPage,
            page + 1,
            selectedStatus,
            startDate,
            endDate
          ),
          getListStatics(
            "phamhung",
            rowsPerPage,
            page + 1,
            selectedStatus,
            startDate,
            endDate
          ),
        ]);

      setOrdersDuongKhue(orderResponseDuongKhue?.data || []);
      setOrdersPhamHung(orderResponsePhamHung?.data || []);
      setTotalStaticsDuongKhue({
        totalCount: staticsResponseDuongKhue?.totalCount || 0,
        totalPrice: orderResponseDuongKhue?.totalPrice || 0,
      });
      setTotalStaticsPhamHung({
        totalCount: staticsResponsePhamHung?.totalCount || 0,
        totalPrice: orderResponsePhamHung?.totalPrice || 0,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePeriodChange = (period: string) => {
    setSelectedPeriod(period);

    let startDate = dayjs();
    let endDate = dayjs();

    switch (period) {
      case "today":
        startDate = dayjs();
        endDate = startDate;
        break;
      case "yesterday":
        startDate = dayjs().subtract(1, "day");
        endDate = startDate;
        break;
      case "lastWeek":
        startDate = dayjs().subtract(1, "week").startOf("week");
        endDate = startDate.add(6, "day");
        break;
      case "lastMonth":
        startDate = dayjs().subtract(1, "month").startOf("month");
        endDate = startDate.add(1, "month").subtract(1, "day");
        break;
      case "thisWeek":
        startDate = dayjs().startOf("week");
        endDate = dayjs().endOf("week");
        break;
      case "thisMonth":
        startDate = dayjs().startOf("month");
        endDate = dayjs().endOf("month");
        break;
      default:
        break;
    }

    if (
      !startDate.isSame(selectedStartDate) ||
      !endDate.isSame(selectedEndDate)
    ) {
      setSelectedStartDate(startDate);
      setSelectedEndDate(endDate);
    }
  };

  useEffect(() => {
    fetchOrdersAndStatics();
  }, [selectedStartDate, selectedEndDate]);

  const formattedStartDate = selectedStartDate
    ? selectedStartDate.format("YYYY-MM-DD")
    : "";
  const formattedEndDate = selectedEndDate
    ? selectedEndDate.format("YYYY-MM-DD")
    : "";

  const handleClick = (branch: string) => {
    navigate("/list/order", { state: { selectedBranch: branch } });
  };

  const handleBranchChange = (event: any) => {
    setSelectedBranch(event.target.value);
  };

  return (
    <Box
      sx={{ mt: isMobile ? 5 : "none" }}
      width="100%"
      maxWidth={1200}
      margin="0 auto"
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h5" fontWeight={700}>
          Trang chủ
        </Typography>

        <Box>
          <Stack direction="row" gap={2} alignItems="center">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Select
                value={selectedBranch}
                size="small"
                onChange={handleBranchChange}
                displayEmpty
                sx={{ minWidth: 150 }}
              >
                <MenuItem value="duongkhue">Dương Khuê</MenuItem>
                <MenuItem value="phamhung">Phạm Hùng</MenuItem>
              </Select>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              bgcolor="#f7f7f7"
              p="10px"
              borderRadius={2}
              gap={1}
              width="100%"
              ref={stackRef}
              sx={{
                cursor: "pointer",
                transition: "background-color 0.2s ease",
                "&:hover": {
                  bgcolor: "#e1e1e1",
                },
              }}
              onClick={() => setShowDatePicker((prev) => !prev)}
            >
              <IoCalendarOutline fontSize={20} />
              <Typography
                variant="body1"
                fontWeight={550}
                display={isMobile ? "none" : "block"}
              >
                Thời gian
              </Typography>
            </Stack>
          </Stack>

          <Popover
            open={showDatePicker}
            anchorEl={stackRef.current}
            onClose={() => setShowDatePicker(false)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Box p={2} width="300px">
              <Select
                value={selectedPeriod}
                onChange={(event) => handlePeriodChange(event.target.value)}
                displayEmpty
                variant="standard"
                sx={{
                  width: "100%",
                  borderRadius: 2,
                  padding: 0.5,
                  "& .MuiSelect-select": {
                    padding: "8px",
                  },
                }}
                renderValue={(value) =>
                  value ? (
                    <Typography fontWeight="550" color="#333">
                      {value === "today"
                        ? "Ngày hôm nay"
                        : value === "yesterday"
                        ? "Ngày hôm qua"
                        : value === "thisWeek"
                        ? "Tuần này"
                        : value === "lastWeek"
                        ? "Tuần vừa qua"
                        : value === "thisMonth"
                        ? "Tháng này"
                        : "Tháng vừa qua"}
                    </Typography>
                  ) : (
                    <Typography color="text.secondary">
                      Chọn thời gian
                    </Typography>
                  )
                }
              >
                <MenuItem value="today">
                  <Typography>Ngày hôm nay</Typography>
                </MenuItem>
                <MenuItem value="yesterday">
                  <Typography>Ngày hôm qua</Typography>
                </MenuItem>
                <MenuItem value="thisWeek">
                  <Typography>Tuần này</Typography>
                </MenuItem>
                <MenuItem value="lastWeek">
                  <Typography>Tuần vừa qua</Typography>
                </MenuItem>
                <MenuItem value="thisMonth">
                  <Typography>Tháng này</Typography>
                </MenuItem>
                <MenuItem value="lastMonth">
                  <Typography>Tháng vừa qua</Typography>
                </MenuItem>
              </Select>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={2} mt={2}>
                  <DatePicker
                    label="Chọn ngày bắt đầu"
                    value={selectedStartDate}
                    onChange={(newValue) => {
                      setSelectedStartDate(newValue);
                    }}
                    slotProps={{ textField: { size: "small" } }}
                    sx={{ width: "100%" }}
                  />
                  <DatePicker
                    label="Chọn ngày kết thúc"
                    value={selectedEndDate}
                    onChange={(newValue) => {
                      setSelectedEndDate(newValue);
                    }}
                    slotProps={{ textField: { size: "small" } }}
                    sx={{ width: "100%" }}
                  />
                </Stack>
              </LocalizationProvider>

              {selectedStartDate && selectedEndDate && (
                <Box
                  display="flex"
                  alignItems="center"
                  mt={2}
                  bgcolor="#f7f7f7"
                  textAlign="center"
                  justifyContent="center"
                  borderRadius={1}
                >
                  <Typography
                    variant="body1"
                    sx={{ mr: 1, pl: 1, fontWeight: 550 }}
                  >
                    {selectedStartDate.format("YYYY/MM/DD")} -{" "}
                    {selectedEndDate.format("YYYY/MM/DD")}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      setSelectedStartDate(null);
                      setSelectedEndDate(null);
                      setSelectedPeriod("");
                    }}
                  >
                    <IoClose />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Popover>
        </Box>
      </Stack>

      <Stack gap={2} width="100%">
        {selectedBranch === "duongkhue" && (
          <Box mb={2}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="body1" fontWeight={700} fontSize={20} mb={1}>
                Chi nhánh Dương Khuê:
              </Typography>
              <Typography
                variant="body2"
                sx={{ cursor: "pointer" }}
                onClick={() => handleClick("duongkhue")}
              >
                Xem chi tiết
              </Typography>
            </Stack>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Box
                  sx={{
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    borderRadius: 2,
                    width: "100%",
                    height: 150,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={40}
                      sx={{ marginBottom: 1 }}
                    />
                  ) : (
                    <Box p={1}>
                      <Typography
                        variant="body1"
                        color="#333"
                        fontWeight={550}
                        mb={1}
                      >
                        Tổng giá tiền
                      </Typography>
                      <Typography variant="h5" fontWeight={700}>
                        {formatCurrency(totalStaticsDuongKhue.totalPrice)}
                      </Typography>
                    </Box>
                  )}

                  {loading ? (
                    <Skeleton variant="rectangular" width="100%" height={30} />
                  ) : (
                    <Box bgcolor="#fafafa" width="100%" p={1.5}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        fontWeight={550}
                      >
                        {selectedStartDate && selectedEndDate
                          ? `Từ ngày: ${selectedStartDate.format(
                              "DD/MM/YYYY"
                            )} - ${selectedEndDate.format("DD/MM/YYYY")}`
                          : "Toàn bộ các ngày"}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Box
                  sx={{
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    borderRadius: 2,
                    width: "100%",
                    height: 150,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={40}
                      sx={{ marginBottom: 1 }}
                    />
                  ) : (
                    <Box p={1}>
                      <Typography
                        variant="body1"
                        color="#333"
                        fontWeight={550}
                        mb={1}
                      >
                        Tổng số món bán được
                      </Typography>
                      <Typography variant="h5" fontWeight={700}>
                        {totalStaticsDuongKhue.totalCount}
                      </Typography>
                    </Box>
                  )}

                  {loading ? (
                    <Skeleton variant="rectangular" width="100%" height={30} />
                  ) : (
                    <Box bgcolor="#fafafa" width="100%" p={1.5}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        fontWeight={550}
                      >
                        {selectedStartDate && selectedEndDate
                          ? `Từ ngày: ${selectedStartDate.format(
                              "DD/MM/YYYY"
                            )} - ${selectedEndDate.format("DD/MM/YYYY")}`
                          : "Toàn bộ các ngày"}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Box
              width="100%"
              borderRadius={2}
              mt={2}
              boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px"
            >
              <Chart
                branch="duongkhue"
                startDate={formattedStartDate}
                endDate={formattedEndDate}
              />
            </Box>
          </Box>
        )}
        {selectedBranch === "phamhung" && (
          <Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="body1" fontWeight={700} fontSize={20} mb={1}>
                Chi nhánh Phạm Hùng:
              </Typography>
              <Typography
                variant="body2"
                sx={{ cursor: "pointer" }}
                onClick={() => handleClick("phamhung")}
              >
                Xem chi tiết
              </Typography>
            </Stack>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Box
                  sx={{
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    borderRadius: 2,
                    width: "100%",
                    height: 150,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={40}
                      sx={{ marginBottom: 1 }}
                    />
                  ) : (
                    <Box p={1}>
                      <Typography
                        variant="body1"
                        color="#333"
                        fontWeight={550}
                        mb={1}
                      >
                        Tổng giá tiền
                      </Typography>
                      <Typography variant="h5" fontWeight={700}>
                        {formatCurrency(totalStaticsPhamHung.totalPrice)}
                      </Typography>
                    </Box>
                  )}

                  {loading ? (
                    <Skeleton variant="rectangular" width="100%" height={30} />
                  ) : (
                    <Box bgcolor="#fafafa" width="100%" p={1.5}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        fontWeight={550}
                      >
                        {selectedStartDate && selectedEndDate
                          ? `Từ ngày: ${selectedStartDate.format(
                              "DD/MM/YYYY"
                            )} - ${selectedEndDate.format("DD/MM/YYYY")}`
                          : "Toàn bộ các ngày"}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Box
                  sx={{
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    borderRadius: 2,
                    width: "100%",
                    height: 150,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={40}
                      sx={{ marginBottom: 1 }}
                    />
                  ) : (
                    <Box p={1}>
                      <Typography
                        variant="body1"
                        color="#333"
                        fontWeight={550}
                        mb={1}
                      >
                        Tổng số món bán được
                      </Typography>
                      <Typography variant="h5" fontWeight={700}>
                        {totalStaticsPhamHung.totalCount}
                      </Typography>
                    </Box>
                  )}

                  {loading ? (
                    <Skeleton variant="rectangular" width="100%" height={30} />
                  ) : (
                    <Box bgcolor="#fafafa" width="100%" p={1.5}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        fontWeight={550}
                      >
                        {selectedStartDate && selectedEndDate
                          ? `Từ ngày: ${selectedStartDate.format(
                              "DD/MM/YYYY"
                            )} - ${selectedEndDate.format("DD/MM/YYYY")}`
                          : "Toàn bộ các ngày"}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Box
              width="100%"
              mt={2}
              borderRadius={2}
              boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px"
            >
              <Chart
                branch="phamhung"
                startDate={formattedStartDate}
                endDate={formattedEndDate}
              />
            </Box>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default Home;
