import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "src/redux-persist/store";

interface ProtectedRouteProps {
  element: JSX.Element;
  requiredRole?: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  element,
  requiredRole,
}) => {
  const token = useSelector((state: RootState) => state.user.token);
  const userRole = useSelector((state: RootState) => state.user.userRole);

  if (!token) {
    return <Navigate to="/auth/login" />;
  }

  if (requiredRole && userRole !== requiredRole) {
    return <Navigate to="/error" />;
  }

  return element;
};

export default ProtectedRoute;
