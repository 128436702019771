import axios from "axios";

export const getBranchConfig = async (
  type: string,
  token: string
): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/api/config/list/branch?type=${type}`;

  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": token,
      },
    };
    const response = await axios.get(url, axiosConfig);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch branch config:", error);
    throw error;
  }
};

export const getListOrder = async (
  branch: string,
  limit: number,
  page: number,
  status?: string,
  startDate?: string,
  endDate?: string,
  token?: string
): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/api/new_order/list?order_by=DESC`;

  const params: any = {
    branch,
    limit,
    page,
    return_count: true,
  };

  if (status) {
    params.status = status;
  }
  if (startDate) {
    params.startDate = startDate;
  }
  if (endDate) {
    params.endDate = endDate;
  }

  const axiosConfig = {
    headers: {
      "X-Authorization": token,
    },
    params,
  };

  try {
    const response = await axios.get(url, axiosConfig);
    const totalCount = Number(response.headers["x-total-count"]);
    const totalPrice = Number(
      response.headers["x-total-price"] || response.headers["X-Total-Price"]
    );

    const safeTotalCount = isNaN(totalCount) ? 0 : totalCount;
    const safeTotalPrice = isNaN(totalPrice) ? 0 : totalPrice;

    return {
      data: response.data,
      totalCount: safeTotalCount,
      totalPrice: safeTotalPrice,
    };
  } catch (error) {
    throw new Error("Failed to fetch order data");
  }
};

export const getListStatics = async (
  branch: string,
  limit: number,
  page: number,
  status?: string,
  startDate?: string,
  endDate?: string,
  token?: string
): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/api/new_order/products/statistics?order_by=DESC`;

  const params: any = {
    branch,
    limit,
    page,
    return_count: true,
  };

  if (status) {
    params.status = status;
  }
  if (startDate) {
    params.startDate = startDate;
  }
  if (endDate) {
    params.endDate = endDate;
  }

  const axiosConfig = {
    headers: {
      "X-Authorization": token,
    },
    params,
  };

  try {
    const response = await axios.get(url, axiosConfig);

    const totalCount = response.data.reduce(
      (acc: number, product: any) => acc + product.count,
      0
    );

    return {
      data: response.data,
      totalCount: totalCount,
    };
  } catch (error) {
    throw new Error("Failed to fetch statistics data");
  }
};

export const updateStatus = async (
  _id: string,
  status: "pending" | "success" | "served" | "close"
): Promise<any> => {
  const requestData = {
    _id: _id,
    status: status,
  };

  const url = `${process.env.REACT_APP_API_URL}/api/new_order/update`;
  return axios.patch(url, requestData);
};
