import axios from "axios";

export const getListUser = async (
  role?: string,
  token?: string
): Promise<any> => {
  const url = process.env.REACT_APP_API_URL + `/api/user/list`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": token,
      },
      params: {
        user_role: role,
      },
    };
    return await axios.get(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const getBranchConfig = async (
  type: string,
  token?: string
): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/api/config/list/branch?type=${type}`;

  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": token,
      },
    };
    const response = await axios.get(url, axiosConfig);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch branch config:", error);
    throw error;
  }
};

export const createUser = async (
  user_email: string,
  user_password: string,
  full_name: string,
  token?: string
): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/api/user/login/sign-up`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": token,
      },
    };

    return await axios.post(
      url,
      { user_email, user_password, full_name },
      axiosConfig
    );
  } catch (error) {
    throw new Error("Failed to create user");
  }
};

export const updateRoleBranch = async (
  _id: string,
  branch: string,
  user_role: string,
  token?: string
): Promise<any> => {
  const axiosConfig = {
    headers: {
      "X-Authorization": token,
    },
  };
  const requestData = { _id, branch, user_role };
  const url = `${process.env.REACT_APP_API_URL}/api/user/admin/update/user`;
  return axios.patch(url, requestData, axiosConfig);
};

export const updateUserStatus = async (
  _id: string,
  user_status: string,
  token: string
): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/api/user/admin/update/user`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": token,
      },
    };

    const body = {
      _id,
      user_status,
    };

    return await axios.patch(url, body, axiosConfig);
  } catch (error) {
    throw new Error("Failed to update user status");
  }
};

export const deleteUser = async (_id: string, token: string): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/api/user/delete/${_id}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": token,
      },
    };

    return await axios.delete(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
