// src/components/ProtectedRoute.tsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { getToken } from 'src/utils/token';

interface ProtectedRouteProps {
    element: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
    const token = getToken();

    if (!token) {
        return <Navigate to="/auth/login" />;
    }

    return element;
};

export default ProtectedRoute;
