import React from "react";
import {
  Modal,
  Box,
  Typography,
  FormControl,
  TextField,
  Button,
} from "@mui/material";
import { CreateUserModalProps } from "../interface";

const CreateUserModal: React.FC<CreateUserModalProps> = ({
  open,
  onClose,
  newUser,
  setNewUser,
  onCreate,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          width: 400,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Tạo Người Dùng
        </Typography>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <TextField
            label="Email"
            value={newUser.email}
            onChange={(e) =>
              setNewUser((prev) => ({ ...prev, email: e.target.value }))
            }
            fullWidth
          />
        </FormControl>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <TextField
            label="Mật khẩu"
            type="password"
            value={newUser.password}
            onChange={(e) =>
              setNewUser((prev) => ({ ...prev, password: e.target.value }))
            }
            fullWidth
          />
        </FormControl>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <TextField
            label="Tên đầy đủ"
            value={newUser.fullName}
            onChange={(e) =>
              setNewUser((prev) => ({ ...prev, fullName: e.target.value }))
            }
            fullWidth
          />
        </FormControl>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button onClick={onClose} color="inherit" sx={{ mr: 1 }}>
            Hủy
          </Button>
          <Button onClick={onCreate} color="primary" variant="contained">
            Tạo
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateUserModal;
