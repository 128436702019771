import React from "react";
import {
  Modal,
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
} from "@mui/material";
import { EditUserModalProps } from "../interface";

const EditUserModal: React.FC<EditUserModalProps> = ({
  open,
  onClose,
  selectedPermission,
  setSelectedPermission,
  selectedBranch,
  setSelectedBranch,
  branchOptions,
  roles,
  onSave,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          width: 400,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Chỉnh sửa quản lý
        </Typography>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Quyền</InputLabel>
          <Select
            value={selectedPermission}
            onChange={(e) => setSelectedPermission(e.target.value)}
            label="Quyền"
          >
            {roles.map((role) => (
              <MenuItem key={role.key} value={role.key}>
                {role.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Chi nhánh</InputLabel>
          <Select
            value={selectedBranch}
            onChange={(e) => setSelectedBranch(e.target.value)}
            label="Chi nhánh"
          >
            {Object.entries(branchOptions).map(([key, title]) => (
              <MenuItem key={key} value={key}>
                {title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button onClick={onClose} color="inherit" sx={{ mr: 1 }}>
            Hủy
          </Button>
          <Button onClick={onSave} color="primary" variant="contained">
            Lưu
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditUserModal;
