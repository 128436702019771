import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { FoodItem } from "./interface";
import { deleteFood, getConfigCategory, getListFood } from "./service";

import { IoMdMore } from "react-icons/io";
import { formatCurrency, formatDateTime } from "src/utils/formatCurrency";
import CreateFoodModal from "./components/CreateFoodModal";
import EditFoodModal from "./components/EditFoodModal";
import CreateCategoryModal from "./components/CreateCategoryModal";
import { useToken } from "src/utils/token";
import { useSelector } from "react-redux";
import { RootState } from "src/redux-persist/store";
import { getBranchConfig } from "../Order/service";
import { BranchIT } from "src/pages/Branch/interface";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import EditCategoryModal from "./components/EditCategoryModal";

const Food: React.FC = () => {
  const token = useToken();
  const userRole = useSelector((state: RootState) => state.user.userRole);
  const userBranch = useSelector((state: RootState) => state.user.userBranch);

  const [foods, setFoods] = useState<FoodItem[]>([]);
  const [categories, setCategories] = useState<
    { title: string; category: string[]; _id: string; desVi: string }[]
  >([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedFood, setSelectedFood] = useState<null | FoodItem>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("ASC");
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalCategoryOpen, setIsModalCategoryOpen] = useState(false);
  const [isModalEditCategoryOpen, setIsModalEditCategoryOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [selectedFoodId, setSelectedFoodId] = useState<string | null>(null);
  const [selectedBranch, setSelectedBranch] = useState<string | undefined>(
    userRole === "super-admin" ? undefined : userBranch || ""
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [branches, setBranches] = useState<BranchIT[]>([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const isMobile = useMediaQuery("(max-width: 600px)");
  const isMobileSmall = useMediaQuery("(max-width: 375px)");
  const isIphone14 = useMediaQuery("(max-width: 430px)");

  const fetchBranches = async () => {
    try {
      const data = await getBranchConfig("branch", token || "");
      setBranches(data.config.option_content);
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  useEffect(() => {
    if (userRole === "super-admin") {
      fetchBranches();
    }
  }, [userRole]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getConfigCategory(selectedBranch);
        const fetchedCategories = response.data?.config?.option_content || [];
        setCategories(fetchedCategories);
        if (fetchedCategories.length === 0) {
          setSelectedCategory("");
        }
      } catch (error) {
        console.error("Error fetching category data:", error);
        setCategories([]);
        setSelectedCategory("");
      }
    };

    fetchCategories();
  }, [selectedBranch]);

  useEffect(() => {
    const fetchFoods = async () => {
      setLoading(true);
      try {
        const validCategory =
          selectedCategory &&
          categories.some(
            (category) => category.category[0] === selectedCategory
          )
            ? selectedCategory
            : "";

        const response = await getListFood(
          sortOrder,
          validCategory,
          selectedBranch
        );
        setFoods(response.data);
      } catch (error) {
        console.error("Error fetching food data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFoods();
  }, [selectedCategory, sortOrder, selectedBranch, categories]);

  useEffect(() => {
    if (userRole !== "super-admin") {
      setSelectedBranch(userBranch || "");
    }
  }, [userBranch, userRole]);

  const branchMap = useMemo(() => {
    const map: Record<string, string> = {};
    branches.forEach((branch) => {
      map[branch.key] = branch.title;
    });
    return map;
  }, [branches]);

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    food: FoodItem
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedFood(food);
    setSelectedFoodId(food?._id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedFoodId(null);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortOrderChange = (event: SelectChangeEvent<"ASC" | "DESC">) => {
    setSortOrder(event.target.value as "ASC" | "DESC");
  };

  const handleCategoryChange = (event: SelectChangeEvent<string>) => {
    setSelectedCategory(event.target.value);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenCategoryModal = () => {
    setIsModalCategoryOpen(true);
  };

  const handleCloseCategoryModal = () => {
    setIsModalCategoryOpen(false);
  };

  const handleOpenEditCategoryModal = () => {
    setIsModalEditCategoryOpen(true);
  };

  const handleCloseEditCategoryModal = () => {
    setIsModalEditCategoryOpen(false);
  };

  const handleCloseEditModal = () => {
    setIsModalEditOpen(false);
  };

  const handleEdit = () => {
    if (selectedFoodId) {
      setIsModalEditOpen(true);
    }

    handleClose();
  };

  const handleDeleteClick = (food: FoodItem) => {
    setSelectedFood(food);
    setOpenDeleteDialog(true);
  };

  const handleDelete = async () => {
    if (!selectedFood) return;

    try {
      await deleteFood(selectedFood._id, token || "");
      setFoods((prevFoods) =>
        prevFoods.filter((food) => food._id !== selectedFood._id)
      );
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error("Error deleting food:", error);
    }
  };

  const getCategoryDesVi = (categoryId: string) => {
    const category = categories.find((cat) => cat.category[0] === categoryId);
    return category ? category.desVi : categoryId;
  };

  return (
    <Box
      sx={{ mt: isMobile ? 5 : "none" }}
      width="100%"
      p={2}
      pt={4}
      maxWidth={1200}
      margin="0 auto"
    >
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        gap={isMobile ? 2 : 0}
      >
        <Typography variant="h5" fontWeight={550}>
          Danh sách món ăn
        </Typography>
        <Stack direction="row" gap={2} alignItems="center">
          <Button
            variant="contained"
            sx={{ textTransform: "none", fontWeight: 550, bgcolor: "#FF1919" }}
            onClick={handleOpenModal}
          >
            Tạo món ăn mới
          </Button>
          <Button
            variant="contained"
            sx={{ textTransform: "none", fontWeight: 550, bgcolor: "#333" }}
            onClick={handleOpenCategoryModal}
          >
            Tạo thể loại mới
          </Button>
          <Button
            variant="contained"
            sx={{ textTransform: "none", fontWeight: 550, bgcolor: "#333" }}
            onClick={handleOpenEditCategoryModal}
          >
            Sửa thể loại
          </Button>
        </Stack>
      </Stack>

      <Stack
        direction={{ xs: "row", sm: "row" }}
        gap={2}
        alignItems="center"
        mt={2}
      >
        {userRole === "super-admin" && (
          <FormControl
            variant="outlined"
            size="small"
            sx={{ width: { xs: "100%", sm: 250 }, mt: 2 }}
          >
            <InputLabel>Chi nhánh</InputLabel>
            <Select
              value={selectedBranch || ""}
              onChange={(e) => setSelectedBranch(e.target.value || undefined)}
              label="Chi nhánh"
            >
              <MenuItem value={undefined}>Tất cả</MenuItem>
              {branches.map((branch) => (
                <MenuItem key={branch.key} value={branch.key}>
                  {branch.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <FormControl
          variant="outlined"
          sx={{ width: { xs: "100%", sm: 120 }, mt: 2 }}
        >
          <InputLabel>Thứ tự</InputLabel>
          <Select
            size="small"
            value={sortOrder}
            onChange={handleSortOrderChange}
            label="Sort"
          >
            <MenuItem value="ASC">Cũ nhất</MenuItem>
            <MenuItem value="DESC">Mới nhất</MenuItem>
          </Select>
        </FormControl>

        <FormControl
          variant="outlined"
          sx={{ width: { xs: "100%", sm: 120 }, mt: 2 }}
          size="small"
        >
          <InputLabel>Thể loại</InputLabel>
          <Select
            value={selectedCategory || ""}
            onChange={handleCategoryChange}
            label="Category"
          >
            <MenuItem value="">Tất cả thể loại</MenuItem>
            {categories.map((category, index) => (
              <MenuItem key={index} value={category.category[0]}>
                {category.desVi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>

      <Box
        sx={{
          width: "100%",
          overflowX: "auto",
          mt: 3,
          borderRadius: "8px",
          maxWidth: isMobileSmall ? "325px" : isIphone14 ? "380px" : "100%",
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            mt: 3,
            minWidth: 680,
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            overflow: "hidden",
            position: "relative",
          }}
        >
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "300px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : foods.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "300px",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                Không có dữ liệu nào
              </Typography>
            </Box>
          ) : (
            <Table>
              <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
                <TableRow>
                  <TableCell sx={{ fontWeight: 550 }}>STT</TableCell>
                  <TableCell sx={{ fontWeight: 550 }}>Ảnh</TableCell>
                  <TableCell sx={{ fontWeight: 550 }}>Tên</TableCell>
                  <TableCell sx={{ fontWeight: 550 }}>Thể loại</TableCell>
                  <TableCell sx={{ fontWeight: 550 }}>Giá tiền</TableCell>
                  <TableCell sx={{ fontWeight: 550 }}>Chi nhánh</TableCell>
                  <TableCell sx={{ fontWeight: 550 }}>Ngày tạo</TableCell>
                  <TableCell sx={{ fontWeight: 550 }}>Hành động</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {foods
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((food, index) => (
                    <TableRow
                      key={food?._id}
                      sx={{
                        transition: "0.2s ease",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.02)",
                        },
                        borderBottom: "1px solid #e0e0e0",
                      }}
                    >
                      <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                      <TableCell>
                        <img
                          src={food?.url}
                          alt={food?.name}
                          style={{ width: 50, height: 50 }}
                        />
                      </TableCell>
                      <TableCell sx={{ fontWeight: 550 }}>
                        {food?.name}
                      </TableCell>
                      <TableCell>
                        {food?.category.map(getCategoryDesVi).join(", ")}
                      </TableCell>
                      <TableCell sx={{ fontWeight: 550 }}>
                        {formatCurrency(food?.variants[0].price)}
                      </TableCell>
                      <TableCell>
                        {branchMap[food.branch] || "Không xác định"}
                      </TableCell>
                      <TableCell>{formatDateTime(food?.createdAt)}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={(event) => handleClick(event, food)}
                        >
                          <IoMdMore />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                          sx={{
                            "& .MuiPaper-root": {
                              boxShadow: "none",
                              border: "1px solid rgba(99, 99, 99, 0.2)",
                            },
                          }}
                        >
                          <MenuItem onClick={handleEdit}>Chỉnh sửa</MenuItem>
                          <MenuItem onClick={() => handleDeleteClick(food)}>
                            Xóa
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={foods.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ mt: 2 }}
        />
      </Box>

      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Xác nhận xóa món ăn</DialogTitle>
        <DialogContent>
          <p>Bạn có chắc chắn muốn xóa món ăn này?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Hủy
          </Button>
          <Button
            onClick={async () => {
              if (selectedFood) {
                await handleDelete();
              }
            }}
            variant="contained"
            sx={{ textTransform: "none", fontWeight: 550, bgcolor: "#FF1919" }}
          >
            Xóa
          </Button>
        </DialogActions>
      </Dialog>

      {selectedFood && (
        <EditFoodModal
          open={isModalEditOpen}
          onClose={handleCloseEditModal}
          food={selectedFood}
          setFoods={setFoods}
        />
      )}

      <CreateFoodModal
        open={isModalOpen}
        onClose={handleCloseModal}
        setFoods={setFoods}
      />

      <CreateCategoryModal
        open={isModalCategoryOpen}
        onClose={handleCloseCategoryModal}
      />

      <EditCategoryModal
        open={isModalEditCategoryOpen}
        onClose={handleCloseEditCategoryModal}
        token={token || ""}
      />
    </Box>
  );
};

export default Food;
