import { FiSearch } from "react-icons/fi";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { useState } from "react";

const SearchComponent = ({ orders, setFilteredOrders }: any) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = orders.filter((order: any) => {
      const orderNumberMatches = order.bill_number.toString().includes(value);

      const categoryMatches =
        (order.table && `bàn ${order.table}`.toLowerCase().includes(value)) ||
        (!order.table && "mang về".includes(value));

      return orderNumberMatches || categoryMatches;
    });

    setFilteredOrders(filtered);
  };

  return (
    <TextField
      size="small"
      variant="outlined"
      placeholder="Tìm kiếm Đơn đặt hàng hoặc Thể loại"
      value={searchTerm}
      onChange={handleSearch}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton>
              <FiSearch />
            </IconButton>
          </InputAdornment>
        ),
      }}
      sx={{
        width: "100%",
        maxWidth: "500px",
        mb: 2,
        mt: 2,
        border: "unset !important",

        "& .MuiOutlinedInput-root": {
          borderRadius: "8px",
          p: 0.5,
          border: "unset !important",
          fontWeight: 500,
        },
      }}
    />
  );
};

export default SearchComponent;
