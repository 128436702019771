import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";

import { useLocation } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Login from "./pages/auth/login";
import ProtectedRoute from "./pages/auth/ProtectedRoute";
import Home from "./pages/Home";
import Food from "./pages/List/Food";
import Order from "./pages/List/Order";
import { SnackbarProvider } from "./components/CustomSnackbar";
import "./App.css";

const AppContent = () => {
  const location = useLocation();

  const hideSidebarPaths = ["/auth/login"];

  return (
    <Box sx={{ display: "flex" }}>
      <SnackbarProvider>
        {!hideSidebarPaths.includes(location.pathname) && <Sidebar />}
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          <Routes>
            <Route path="/auth/login" element={<Login />} />
            <Route path="/" element={<ProtectedRoute element={<Home />} />} />
            <Route
              path="/list/food"
              element={<ProtectedRoute element={<Food />} />}
            />
            <Route
              path="/list/order"
              element={<ProtectedRoute element={<Order />} />}
            />
          </Routes>
        </Box>
      </SnackbarProvider>
    </Box>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
