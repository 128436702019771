// CreateBranchModal.tsx
import React from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Stack,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { CreateBranchModalProps } from "../interface";
import { listBanks } from "src/utils/bank";

const CreateBranchModal: React.FC<CreateBranchModalProps> = ({
  open,
  handleClose,
  handleSubmit,
  handleFileChange,
  handleBranchDataChange,
  uploading,
  imageURL,
  branchData,
  step,
  handleNextStep,
  handlePreviousStep,
  fileInputRef,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 400,
          bgcolor: "background.paper",
          borderRadius: 2,
          padding: 3,
          boxShadow: 24,
        }}
      >
        <Typography variant="h6" mb={2}>
          {step === 1 ? "Tạo chi nhánh mới" : "Xác nhận thông tin chi nhánh"}
        </Typography>

        {step === 1 && (
          <>
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: "200px",
                cursor: "pointer",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  border: imageURL ? "none" : "2px dashed #ccc",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "8px",
                  backgroundColor: "#f9f9f9",
                  transition: "background-color 0.3s",
                  "&:hover": {
                    backgroundColor: imageURL
                      ? "rgba(0, 0, 0, 0.5)"
                      : "#f1f1f1",
                  },
                }}
                onClick={() => fileInputRef.current?.click()}
              >
                {uploading ? (
                  <CircularProgress size={24} />
                ) : (
                  <>
                    {imageURL ? (
                      <Box
                        sx={{
                          position: "relative",
                          width: "100%",
                          height: "100%",
                          borderRadius: "8px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={imageURL}
                          alt="Uploaded"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                            borderRadius: "8px",
                          }}
                        />
                      </Box>
                    ) : (
                      <Typography variant="body1" color="textSecondary">
                        Nhấn vào đây để tải ảnh
                      </Typography>
                    )}
                  </>
                )}

                <input
                  ref={fileInputRef}
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </Box>
            </Box>
            <TextField
              label="Key"
              variant="outlined"
              fullWidth
              value={branchData[0].key}
              onChange={(e) => handleBranchDataChange("key", e.target.value)}
              margin="normal"
            />
            <TextField
              label="Tên chi nhánh"
              variant="outlined"
              fullWidth
              value={branchData[0].title}
              onChange={(e) => handleBranchDataChange("title", e.target.value)}
              margin="normal"
            />
            <Stack direction="row" justifyContent="flex-end" spacing={2} mt={3}>
              <Button onClick={handleClose}>Hủy</Button>
              <Button
                onClick={handleNextStep}
                variant="contained"
                sx={{
                  textTransform: "none",
                  fontWeight: 550,
                  bgcolor: "#FF1919",
                }}
              >
                Tiếp theo
              </Button>
            </Stack>
          </>
        )}

        {step === 2 && (
          <>
            <FormControl fullWidth margin="normal">
              <InputLabel>Ngân hàng</InputLabel>
              <Select
                value={branchData[0].bin || ""}
                onChange={(e) => {
                  const selectedBank = listBanks.find(
                    (bank) => bank.bin === e.target.value
                  );
                  handleBranchDataChange("bin", e.target.value);
                  if (selectedBank) {
                    handleBranchDataChange("logo", selectedBank.logo);
                    handleBranchDataChange("shortName", selectedBank.shortName);
                  }
                }}
                label="Ngân hàng"
              >
                {listBanks.map((bank) => (
                  <MenuItem key={bank.bin} value={bank.bin}>
                    {`Ngân hàng ${bank.shortName}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Số điện thoại"
              variant="outlined"
              fullWidth
              value={branchData[0].phone}
              onChange={(e) => handleBranchDataChange("phone", e.target.value)}
              margin="normal"
            />
            <TextField
              label="Số tài khoản"
              variant="outlined"
              fullWidth
              value={branchData[0].card_id}
              onChange={(e) =>
                handleBranchDataChange("card_id", e.target.value)
              }
              margin="normal"
            />
            <TextField
              label="Tên chủ thẻ"
              variant="outlined"
              fullWidth
              value={branchData[0].card_name}
              onChange={(e) =>
                handleBranchDataChange("card_name", e.target.value)
              }
              margin="normal"
            />
            <Stack direction="row" justifyContent="flex-end" spacing={2} mt={3}>
              <Button onClick={handlePreviousStep}>Quay lại</Button>
              <Button
                onClick={handleSubmit}
                variant="contained"
                sx={{
                  textTransform: "none",
                  fontWeight: 550,
                  bgcolor: "#FF1919",
                }}
              >
                Tạo
              </Button>
            </Stack>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default CreateBranchModal;
