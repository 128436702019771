import axios from "axios";

export const getListCoupon = async (token: string): Promise<any> => {
  const url = process.env.REACT_APP_API_URL + `/api/coupon/list`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": token,
      },
    };
    return await axios.get(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const createCoupon = async (
  title: string,
  type: string,
  total: string,
  promotion: string,
  promotion_type: string,
  expired: string,
  availableAt: string,
  code: string,
  token: string
): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/api/coupon/create`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": token,
      },
    };

    return await axios.post(
      url,
      {
        title,
        type,
        total,
        promotion,
        promotion_type,
        expired,
        availableAt,
        code,
      },
      axiosConfig
    );
  } catch (error) {
    throw new Error("Failed to create user");
  }
};

export const updateCoupon = async (
  _id: string,
  title: string,
  type: string,
  total: string,
  promotion: number,
  promotion_type: string,
  expired: string,
  availableAt: string,
  code: string,
  token: string
): Promise<any> => {
  const axiosConfig = {
    headers: {
      "X-Authorization": token,
    },
  };
  const requestData = {
    _id,
    title,
    type,
    total,
    promotion,
    promotion_type,
    expired,
    availableAt,
    code,
  };
  const url = `${process.env.REACT_APP_API_URL}/api/coupon/update`;
  return axios.patch(url, requestData, axiosConfig);
};

export const deleteCoupon = async (
  _id: string,
  token: string
): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/api/coupon/delete/${_id}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": token,
      },
    };

    return await axios.delete(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
