import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import { Box } from "@mui/material";

import { useLocation } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Login from "./pages/auth/login";
import ProtectedRoute from "./pages/auth/ProtectedRoute";
import Home from "./pages/Home";
import Food from "./pages/List/Food";
import Order from "./pages/List/Order";
import { SnackbarProvider } from "./components/CustomSnackbar";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import Branch from "./pages/Branch";
import { useEffect } from "react";
import { RootState } from "./redux-persist/store";
import Error from "./pages/Error";
import CouponList from "./pages/Coupon";
import User from "./pages/User";

const AppContent = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state: RootState) => state.user.token);
  const userRole = useSelector((state: RootState) => state.user.userRole);
  const loading = useSelector((state: RootState) => state.user.loading);

  useEffect(() => {
    if (loading) return;

    if (!token || !userRole) {
      navigate("/auth/login");
    }
  }, [dispatch, navigate, token, userRole, loading]);

  const hideSidebarPaths = ["/auth/login"];

  return (
    <Box sx={{ display: "flex" }}>
      <SnackbarProvider>
        {!hideSidebarPaths.includes(location.pathname) && <Sidebar />}

        <Routes>
          <Route path="/auth/login" element={<Login />} />
          <Route path="/" element={<ProtectedRoute element={<Home />} />} />
          <Route
            path="/user"
            element={
              <ProtectedRoute element={<User />} requiredRole="super-admin" />
            }
          />
          <Route
            path="/branch"
            element={
              <ProtectedRoute element={<Branch />} requiredRole="super-admin" />
            }
          />
          <Route
            path="/coupon"
            element={
              <ProtectedRoute
                element={<CouponList />}
                requiredRole="super-admin"
              />
            }
          />
          <Route
            path="/error"
            element={<ProtectedRoute element={<Error />} />}
          />
          <Route
            path="/list/food"
            element={<ProtectedRoute element={<Food />} />}
          />
          <Route
            path="/list/order"
            element={<ProtectedRoute element={<Order />} />}
          />
        </Routes>
      </SnackbarProvider>
    </Box>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
