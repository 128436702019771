// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiTooltip-tooltip {
  background-color: unset !important;
}

.MuiMenu-paper {
  box-shadow: none !important;
  border: 1px solid #777777b7;
}
`, "",{"version":3,"sources":["webpack://./src/pages/List/Order/assets/css/Order.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;AACpC;;AAEA;EACE,2BAA2B;EAC3B,2BAA2B;AAC7B","sourcesContent":[".MuiTooltip-tooltip {\n  background-color: unset !important;\n}\n\n.MuiMenu-paper {\n  box-shadow: none !important;\n  border: 1px solid #777777b7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
