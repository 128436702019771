import React, { createContext, useContext, useState, ReactNode } from "react";
import { Snackbar, SnackbarOrigin, SnackbarCloseReason } from "@mui/material";

import { IoClose } from "react-icons/io5";

interface SnackbarContextProps {
  showSnackbar: (
    message: string,
    severity: "success" | "error" | "warning"
  ) => void;
}

const SnackbarContext = createContext<SnackbarContextProps | undefined>(
  undefined
);

export const SnackbarProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error" | "warning">(
    "success"
  );

  const showSnackbar = (msg: string, sev: "success" | "error" | "warning") => {
    setMessage(msg);
    setSeverity(sev);
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor:
              severity === "success"
                ? "#50C878"
                : severity === "error"
                ? "#FF1919"
                : severity === "warning"
                ? "#FFBF00"
                : "grey",
          },
        }}
        autoHideDuration={2000}
        onClose={handleClose}
        message={message}
        action={<IoClose onClick={handleClose} fontSize={18} />}
      />
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
};
